import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { ArrowBack } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CodeInput } from "../../components/CodeInput";
import { Card } from "../../models";
import { callApi } from "../../utils/fetchData";
import { useConfig } from "../../utils/useConfig";
import { useData } from "../../utils/useData";

export const ResetPin = () => {
  const defaultPinArray = Array.from({ length: 4 }, () => "");
  const [pin, setPin] = useState(defaultPinArray);
  const [confirmPin, setConfirmPin] = useState(defaultPinArray);
  const [activeCard, setActiveCard] = useState<Card>();

  const [error, setError] = useState("");
  const [page, setPage] = useState<number>(0);

  const { getToken } = useKindeAuth();
  const { config } = useConfig();
  const { userInfoData, employee, cards } = useData();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await callApi(
        `${config?.API_URL}/employers/${userInfoData?.employerId}/cards/${activeCard?.id}/pin`,
        "PUT",
        {
          pin: pin.join("")
        },
        getToken
      );

      if (response && !response.ok)
      {
        throw new Error("There was a problem updating your pin");
      }
    },
    onSuccess: async () => {
      toast.success("Pin successfully updated");
      await employee.refetch();
      await cards.refetch();
      navigate("/");
    },
    onError: (error: Error) => {
      console.error(error.message);
      setError(error.message);
    }
  });

  function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (page === 0) {
      if (!activeCard) {
        setError("Card not found");
        return;
      }
    }

    if (page < 1) {
      setPage(page + 1);
      return;
    }

    const pinString = pin.join("");

    if (pinString !== confirmPin.join("")) {
      setError("Pins do not match");
      setPage(0);
      setPin(defaultPinArray);
      setConfirmPin(defaultPinArray);
      return;
    }

    mutation.mutate();
  }

  useEffect(() => {
    if (!cards.data?.items) return;
    const activeCard = cards.data?.items.find((c) => c.status === "ACTIVE");

    setActiveCard(activeCard ?? undefined);
  }, [cards]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <IconButton
          onClick={() => {
            if (page === 0) return navigate("/");
            setPage(page - 1);
          }}
        >
          <ArrowBack />
        </IconButton>
        {error && (
          <Grid item>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
        <Grid item xs={12} display="flex" flexDirection="column" gap={1}>
          <Typography variant="h6">Reset Pin</Typography>
          {page === 0 ? (
            <>
              <Typography>Use 4 numbers to set your PIN code</Typography>
              <Box display="flex" justifyContent="center" gap={2}>
                <CodeInput
                  code={pin}
                  setCode={(newPin) => {
                    setPin(newPin);
                    error && setError("");
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Typography>Confirm the PIN you just created</Typography>
              <Box display="flex" justifyContent="center" gap={2}>
                <CodeInput code={confirmPin} setCode={setConfirmPin} />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {page < 1 ? (
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={page === 0 && !pin.every((x) => x)}
            >
              Next
            </Button>
          ) : (
            <LoadingButton
              type="submit"
              loading={mutation.isLoading}
              variant="contained"
              fullWidth
              disabled={!confirmPin.every((x) => x)}
            >
              Reset PIN
            </LoadingButton>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
